'use client'

import { pushEventGA4 } from '@theme/utils/gtm';
import RecommendationContent from './recommendation-content';
import { Icon, Link } from 'components';

export type RecommendationType = {
  title: {
    value: string;
  };
  btn_text?: {
    value: string;
  };
  btn_url?: {
    value: string;
  };
};

export default function NewArrivalsRecommendation(props: {
  data: { attributes: RecommendationType; name: string; slug: string; };
}) {
  const { data } = props;

  const pushSelectEvent = () => {
    pushEventGA4({
      event: 'select_promotion',
      ecommerce: {
        creative_name: data?.name ?? '',
        creative_slot: data?.slug ?? '',
        promotion_id: data?.slug ? data?.slug?.replaceAll('-', '_') + '_0' : '',
        promotion_name: data?.name ? data?.name + ' 0' : ''
      }
    });
  }

  return (
    <div className="flex flex-col gap-5 lg:gap-8 pl-4 lg:pl-0">
      <div className="flex justify-between items-center">
        {data?.attributes?.title?.value && (
          <h3 className="font-serif text-2xl text-primary-400 text-center lg:text-4xl">
            {data.attributes.title.value}
          </h3>
        )}
        <div>
          {data?.attributes?.btn_text?.value && (
            <Link href={data?.attributes?.btn_url?.value} onClick={pushSelectEvent}>
              <span className="font-sans block lg:hidden text-base text-secondary border-b h-5 mr-4 border-secondary">
                {data?.attributes?.btn_text?.value}
              </span>
              <div className="hidden lg:flex justify-center items-center gap-7 text-sm text-white bg-black py-[14px] px-[24px]">
                <span className="uppercase -mb-1 font-sans">
                  {data?.attributes?.btn_text?.value}
                </span>
                <Icon name="arrow-right" size={12} className="rtl:rotate-180" />
              </div>
            </Link>
          )}
        </div>
      </div>

      <div className="w-full">
        <RecommendationContent data={data} />
      </div>
    </div>
  );
}
