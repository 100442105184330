'use client';

import { useAddProductToBasket } from 'hooks';
import { useLocalization } from '@akinon/next/hooks';

import { Product } from '@akinon/next/types';
import { Button } from 'components';

interface Props {
  product: Product;
}

export const AddToBasketButton = (props: Props) => {
  const { product } = props;
  const [addProduct, { isLoading }] = useAddProductToBasket();
  const { t } = useLocalization();

  const addProductToCart = () => {
    addProduct({ product: product.pk, quantity: 1, attributes: {} });
  };

  return (
    <Button
      disabled={!product.in_stock || isLoading}
      className="w-full h-12 disabled:bg-opacity-50 disabled:cursor-not-allowed"
      onClick={addProductToCart}
    >
      {t('product.add_to_cart')}
    </Button>
  );
};
