'use client';

import { useMediaQuery } from '@akinon/next/hooks';
import { BrandLogoProps } from 'types';
import clsx from 'clsx';

import { SwiperNavigation, SwiperReact, SwiperSlide } from 'components';
import { Image } from './image';
import { useEffect, useState } from 'react';
import { Autoplay } from 'swiper';

export default function BrandLogo({ top_info, brand_logo }: BrandLogoProps) {
  const [textIndex, setTextIndex] = useState(0);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const matches = useMediaQuery('(min-width: 768px)');
  const [isDesktop, setIsDesktop] = useState(false);

  const onImageHover = (index) => {
    setTextIndex(index);
    setIsMouseOver(true);
  };

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);

  const top_link_html = (item) => {
    return (
      <a
        href={item.value?.top_text_link ? item.value?.top_text_link : '#'}
        target="_blank"
        dangerouslySetInnerHTML={{
          __html: item.value?.top_text
        }}
        className={clsx(
          'text-primary-400 text-[15px] text-center font-bold font-sans',
          'leading-[1.6] italic sm:not-italic',
          `${
            item.value?.top_text_link
              ? 'hover:underline'
              : 'cursor-default pointer-events-none'
          }`
        )}
      />
    );
  };

  const bottom_link_html = (item) => {
    return (
      <a
        href={item.value?.bottom_text_link ? item.value?.bottom_text_link : '#'}
        target="_blank"
        dangerouslySetInnerHTML={{
          __html: item.value?.bottom_text
        }}
        className={clsx(
          'text-primary-400 text-[15px] text-center font-bold font-sans',
          'leading-[1.6] italic sm:not-italic',
          `${
            item.value?.bottom_text_link ? 'hover:underline' : 'cursor-default'
          }`
        )}
      />
    );
  };

  return (
    <div className="container px-4 2xl:px-0">
      <div className="flex flex-col lg:flex-row lg:justify-between space-y-8 lg:space-y-0">
        {top_info?.value?.title && (
          <h2 className="text-2xl lg:text-[35px] text-primary-400 font-serif">
            {top_info?.value?.title}
          </h2>
        )}

        {top_info?.value?.description && (
          <p className="sm:w-[664px] text-primary-400 text-[15px] leading-[1.6] ">
            {top_info?.value?.description}
          </p>
        )}
      </div>

      <div className="flex justify-between mt-8 mb-0 sm:my-8">
        {(() => {
          if (isDesktop) {
            return (
              <>
                {brand_logo?.map((item, index) => {
                  return (
                    <Image
                      key={index}
                      src={item.kwargs?.value?.image?.url}
                      alt={item.value?.image_alt}
                      width={168}
                      height={112}
                      className={clsx(
                        'w-[168px] h-[112px] object-contain hover:grayscale-0',
                        'hover:opacity-100 transition-all',
                        `${
                          textIndex === index && isMouseOver
                            ? 'opacity-100 grayscale-0'
                            : 'opacity-40 grayscale'
                        }`
                      )}
                      onMouseOver={() => onImageHover(index)}
                      onMouseLeave={() => setIsMouseOver(true)}
                    />
                  );
                })}
              </>
            );
          } else {
            return (
              <SwiperReact
                modules={[SwiperNavigation, Autoplay]}
                slidesPerView={1}
                spaceBetween={32}
                autoplay={{
                  delay: 3000
                }}
              >
                {brand_logo?.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className="custom-swiper-slide text-center"
                    >
                      <Image
                        src={item.kwargs?.value?.image?.url}
                        alt={item.value?.image_alt}
                        width={240}
                        height={160}
                        className="w-60 h-40 object-contain mb-6"
                      />

                      {top_link_html(item)}
                      {bottom_link_html(item)}
                    </SwiperSlide>
                  );
                })}
              </SwiperReact>
            );
          }
        })()}
      </div>

      {brand_logo?.map((item, index) => {
        return isDesktop ? (
          <div
            key={index}
            className={`${
              textIndex === index ? 'flex flex-col items-center' : 'hidden'
            }`}
          >
            {top_link_html(item)}
            {bottom_link_html(item)}
          </div>
        ) : null;
      })}
    </div>
  );
}
