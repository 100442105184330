'use client';

import { ImageType } from '@akinon/next/types';
import {
  SwiperReact,
  SwiperSlide,
  SwiperPagination,
  SwiperNavigation,
  Link
} from 'components';
import { Image } from '@akinon/next/components/image';
import { Autoplay } from 'swiper';

import { Icon } from './icon';
import { useEffect } from 'react';
import { pushEventGA4 } from '@theme/utils/gtm';

interface ContentItem {
  kwargs: {
    value: {
      mobile_image: ImageType;
      desktop_image: ImageType;
    };
  };

  value: {
    title: string;
    description: string;
    button_text: string;
    button_link: string;
    text_placement: string;
    alt: string;
    disabled: string;
    image_alt: string;
    url: string;
  };
}

type HeroSliderProps = {
  content: ContentItem[];
  data;
};

const HeroSliderforHome = (props: HeroSliderProps) => {
  const textPlacement = (item: string) => {
    switch (item) {
      case 'left':
        return 'lg:items-start';
      case 'right':
        return 'lg:items-end';
      default:
    }

    return 'lg:items-end';
  };

  const HeroItemHtml = (item: ContentItem, index: number) => {
    return (
      <div className="relative">
        <Image
          src={item.kwargs?.value?.mobile_image?.url}
          alt={item.value?.image_alt || 'Forest Essentials - Hero Banner Image'}
          fill
          aspectRatio={1}
          sizes="100vw"
          className="sm:hidden"
          loading={index === 0 ? 'eager' : 'lazy'}
          fetchPriority={index === 0 ? 'high' : 'auto'}
        />

        <Image
          src={item.kwargs?.value?.desktop_image?.url}
          alt={item.value?.image_alt || 'Forest Essentials - Hero Banner Image'}
          fill
          aspectRatio={3.4}
          sizes="100vw"
          className="hidden sm:block"
          loading={index === 0 ? 'eager' : 'lazy'}
          fetchPriority={index === 0 ? 'high' : 'auto'}
        />
        <div
          className={`container absolute left-0 right-0 lg:top-0 bottom-3 lg:bottom-0 flex flex-col justify-center ${textPlacement(
            item.value.text_placement
          )}`}
        >
          <div className="w-full lg:w-72 m-auto lg:m-0 text-center">
            {item.value.title && (
              <h2 className="text-red text-2xl lg:text-4xl mb-1">
                {item.value.title}
              </h2>
            )}

            {item.value.description && (
              <p
                className="lg:text-lg font-sans"
                dangerouslySetInnerHTML={{
                  __html: item?.value?.description
                }}
              />
            )}

            {item.value.button_text && item.value.url && (
              <Link
                href={item.value.url}
                className="w-40 h-12 flex items-center justify-center text-lg uppercase border-secondary rounded-full border-2 mt-1 space-x-1 rtl:space-x-reverse lg:mt-8 mx-auto"
              >
                <span>{item.value.button_text}</span>

                <Icon
                  name="chevron-right"
                  className="-mt-0.5 rtl:rotate-180"
                  size={10}
                ></Icon>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  const ecommerce = {
    creative_name:
      props.data?.attributes?.creative_name?.value ?? props.data?.name,
    creative_slot:
      props.data?.attributes?.creative_slot?.value ?? props.data?.slug,
    promotion_id:
      props.data?.attributes?.promotion_id?.value ?? 'hero_home_slider',
    promotion_name:
      props.data?.attributes?.promotion_name?.value ?? 'Hero Home Slider'
  };

  const customizePushEventData = (item, index) => {
    return {
      ...ecommerce,
      promotion_id: `hero_home_slider-${index}`,
      promotion_name: item.value.alt ?? `Hero Home Slider ${index}`
    };
  };

  useEffect(() => {
    props.data?.attributes?.hero_home_slider?.map((item, index) => {
      pushEventGA4({
        event: 'view_promotion',
        ecommerce: customizePushEventData(item, index)
      });
    });
  }, []);

  const handlePromotionClick = (item, index) => {
    pushEventGA4({
      event: 'select_promotion',
      ecommerce: customizePushEventData(item, index)
    });
  };

  return (
    <div className="relative">
      <div className="absolute inset-y-1/2 right-0 lg:right-2 z-40 cursor-pointer swiper-button block lg:hidden image-swiper-button-next">
        <div className="bg-gray-600 bg-opacity-50 px-1 py-8">
          <Icon name="chevron-right" className="text-white" size={14}></Icon>
        </div>
      </div>
      <div className="absolute inset-y-1/2 left-0 lg:left-2 z-40 swiper-button block lg:hidden image-swiper-button-prev">
        <div className="bg-gray-600 bg-opacity-50 px-1 py-8">
          <Icon name="chevron-left" className="text-white" size={14}></Icon>
        </div>
      </div>

      <SwiperReact
        slidesPerView={1}
        pagination={{
          el: '.swiper-pagination-custom',
          clickable: true
        }}
        modules={[SwiperPagination, SwiperNavigation, Autoplay]}
        autoplay={{
          delay: 4000,
          pauseOnMouseEnter: true
        }}
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
          disabledClass: 'swiper-button-disabled'
        }}
      >
        {props?.content?.map((item, index) => {
          return item.value.disabled !== 'yes' ? (
            <SwiperSlide key={index}>
              {(() => {
                if (!item.value.button_text) {
                  return (
                    <Link
                      href={item.value.url}
                      onClick={() => handlePromotionClick(item, index)}
                    >
                      {HeroItemHtml(item, index)}
                    </Link>
                  );
                } else {
                  return HeroItemHtml(item, index);
                }
              })()}
            </SwiperSlide>
          ) : null;
        })}
        <div className="absolute bottom-0 z-50 text-center hidden lg:block swiper-pagination-custom"></div>
      </SwiperReact>
    </div>
  );
};

export default HeroSliderforHome;
