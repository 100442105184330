'use client';

import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { IMAGE_OPTIONS } from '../../../utils';
import { Product } from '@akinon/next/types';

import { Image } from '@akinon/next/components/image';
import clsx from 'clsx';

import { useMediaQuery } from '@akinon/next/hooks';
import { MakeupProductBannerType } from 'types';

import { AddToBasketButton } from '../components/add-to-basket-button';
import { ProductImage } from '../components/product-image';
import { ProductPrice } from '../components/product-price';
import { ProductName } from '../components/product-name';

interface Props {
  data: MakeupProductBannerType;
  product: Product;
  imageKey?: keyof typeof IMAGE_OPTIONS;
  width?: number;
  height?: number;
  index: number;
}

export const MakeupItem = (props: Props) => {
  // TODO: Static image will change (TR)
  const { data, product, width, height, index} = props;
  
  const matches = useMediaQuery('(min-width: 1024px)');
  const [isDesktop, setIsDesktop] = useState(false);
  const [viewed, setViewed] = useState(false);

  const isImageContent= data.small_content.kwargs.value.image_or_video.value === 'image'
  const isReverseOrder= data.reverse_order.value === 'true'
  const video_link = data.small_content.value.video_link || '';

  const { ref, inView } = useInView();
  
  const image_url = product.productimage_set[0]?.image;
  const product_name = product.name;

  useEffect(() => {
    setIsDesktop(matches);
  }, [matches]);
  

  useEffect(() => {
    if (!viewed && inView) {
      setViewed(true);
    }
  }, [inView, product, viewed]);

  return (
    <div className='flex flex-col gap-3'>
      <div className={clsx(
        {'h-full': isImageContent===true },
        'grid grid-cols-2',
        'lg:gap-4',
      )}>
        {/* Small Content (Image or Video) */}
        { isImageContent === true ?
          <Image 
            src={image_url}
            alt={product_name}
            fill aspectRatio={176/314} sizes={"(min-width: 1024px) 50vw, 100vw"}
          />:
          <iframe 
            className='min-h-[314px] h-full lg:h-[428px] xl:h-[493px] 2xl:h-[578px]'
            src={video_link}
            title='YouTube Video Player'
            width="100%"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"    
          />
        }

        {/* Product Item */}
        <div className={clsx(
          'h-full',
          {'lg:order-first' : isReverseOrder === true}
        )}>
          <div
            className="text-sm text-left flex flex-col justify-between bg-gray-20 h-full"
            ref={ref}
          >
            <ProductImage product={product} width={width} height={height} />
            <div className={clsx(
              'relative px-3 pt-3 pb-2 h-full flex flex-col space-between',
              'lg:pb-3'
              )}>
              <div className="h-full flex justify-between flex-col md:mb-3">
                <div className='flex flex-col'>
                  <ProductName product={product} index={index} />
                  <div className="text-primary-400 text-xs leading-4 font-serif mt-1">
                    50 g
                  </div>
                </div>
                <div className='flex flex-col gap-4'>
                  <div className='flex gap-2'>
                    <div className={clsx(
                      'w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center',
                      'border-black border'
                      )}>
                      <div className={clsx(
                        'w-[25px] h-[25px] rounded-full bg-black',
                        'w-[20px] h-[20px]'
                      )}></div>
                    </div>

                    <div className={clsx('w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center')}>
                      <div className={clsx('w-[25px] h-[25px] rounded-full bg-blue')}></div>
                    </div>

                    <div className={clsx('w-[25px] h-[25px] rounded-full bg-white flex items-center justify-center')}>
                      <div className={clsx('w-[25px] h-[25px] rounded-full bg-red')}></div>
                    </div>
                  </div>
                  <ProductPrice product={product} />
                </div>
              </div>
              
              { isDesktop &&
                <div>
                  <AddToBasketButton product={product} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      { !isDesktop &&
        <AddToBasketButton product={product} />
      }
    </div>
  );
};
