'use client';

import { Product } from '@akinon/next/types';
import { Price } from 'components';
interface Props {
  product: Product;
  has_new_arrival_text?: boolean;
}

export const ProductPrice = (props: Props) => {
  const { product } = props;

  const retail_price = product.retail_price;
  const price = product.price;

  return (
    <div>
      {retail_price && (
        <Price
          value={retail_price}
          useCurrencyAfterPrice={false}
          className="font-normal line-through mr-3 text-sm font-serif uppercase"
        />
      )}
      <Price
        className="text-primary-400 text-sm font-serif uppercase"
        value={price}
        useCurrencyAfterPrice={false}
        data-testid="product-price"
      />
    </div>
  );
};





