'use client';

import { Image } from '@akinon/next/components/image';
import { useState, useRef } from 'react';

export const HomeIframeLoader = ({videoURL}:{videoURL:string}) => {
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const iframeRef = useRef(null as HTMLIFrameElement | null);

  const videoID = videoURL?.split('embed/')?.[1]?.split('?')?.[0];
  const thumbnailURL = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;

  const IframeContent = () => {
    return (
        <iframe
            ref={iframeRef}
            className='w-full h-[234px] md:h-[381px] lg:h-[442px] bg-black'
            src={videoID ? videoURL + '?autoplay=1' : videoURL}
            title="YouTube Video Player"
            width="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
    )
  }

  if(!videoID) return IframeContent();

  return (
    <div className="md:absolute flex items-center justify-center w-full h-full left-0 top-0">
      {isIframeVisible ? <IframeContent /> :
        (
          <div 
            className='relative w-full h-[234px] md:h-[381px] lg:h-[442px] cursor-pointer bg-black'
            onClick={() => setIsIframeVisible(true)}
          >
            <Image
              className='h-[234px] md:h-[381px] lg:h-[442px]'
              fill
              aspectRatio={16/9}
              sizes='100%'
              src={thumbnailURL}
              alt='Forest Essentials - Video Banner Thumbnail'
              loading='lazy'
              style={
                {
                  objectFit: 'contain',
                }
              }
            />
            <svg className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' version="1.1" width={68} height={48} viewBox="0 0 68 48"><path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>
          </div>
        )
      }
    </div>
  );
}
