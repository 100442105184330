'use client';

import { useLocalization } from '@akinon/next/hooks';
import { pushProductClicked } from '../../../utils/gtm';

import { Product } from '@akinon/next/types';
import { Link } from 'components';
import { Image } from '@akinon/next/components/image';

interface Props {
  product: Product;
  width?: number;
  height?: number;
  has_new_arrival_text?: boolean;
}

export const ProductImage = (props: Props) => {
  const { product, width, height, has_new_arrival_text } = props;
  const { t } = useLocalization();

  const image_url = product.productimage_set[0]?.image;
  const absolute_url = product.absolute_url;
  const product_name = product.name;

  return (
    <div className="relative">
      <Link href={absolute_url} onClick={() => pushProductClicked(product)}>
        {image_url ? (
          <Image
            fill
            src={image_url}
            alt={product_name}
            aspectRatio={width / height}
            sizes={'100vw'}
          />
        ) : (
          <Image
            className="h-full"
            src="/noimage.jpg"
            fill
            aspectRatio={width / height}
            sizes={'100vw'}
            alt={product_name}
          />
        )}
      </Link>
      {has_new_arrival_text && (
        <div className="absolute top-1 right-1 bg-white text-primary-400 px-4 py-1 font-sans text-[9px] leading-[11px] shadow-new-arrival">
          {t('product.new_arrival')}
        </div>
      )}
    </div>
  );
};
