'use client';

import { createEcommerceItem } from '@theme/utils';
import { pushEventGA4 } from '@theme/utils/gtm';
import { Icon, SwiperNavigation, SwiperReact, SwiperSlide } from 'components';
import { useEffect } from 'react';

import { ProductItem } from 'views/product-item/';

export default function RecommendationContent({ data }) {

  const ecommerce = {
    creative_name: data?.name ?? '',
    creative_slot: data?.slug ?? '',
    promotion_id: data?.slug ? data?.slug?.replaceAll('-', '_') + '_0' : '',
    promotion_name: data?.name ? data?.name + ' 0' : ''
  };

  const customizePushEventData = () => {
    return {
      ...ecommerce,
      items: data?.products?.map((product, index) => createEcommerceItem({product, index}))
    };
  };

  useEffect(() => {
    if (data?.products?.length > 0) {
      pushEventGA4({
        event: 'view_promotion',
        ecommerce: customizePushEventData()
      });
    }
  }, [data]);

  return (
    <div className="flex items-center relative w-full">
      <div className="hidden lg:flex justify-center items-center z-10 absolute -translate-y-10 left-0 rtl:left-full">
        <div
          id={`swiper-back-` + data?.slug}
          className="flex justify-center cursor-pointer bg-transparent pl-1 pr-4 py-4"
        >
          <Icon className="rtl:rotate-180" name="chevron-left" size={18} />
        </div>
      </div>
      <SwiperReact
        className="w-full"
        slidesPerView={1.5}
        modules={[SwiperNavigation]}
        spaceBetween={24}
        navigation={{
          nextEl: '#swiper-forward-' + data?.slug,
          prevEl: '#swiper-back-' + data?.slug
        }}
        breakpoints={{
          768: {
            slidesPerView: 3
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 32
          }
        }}
      >
        {data?.products?.map((product, index) => (
          <SwiperSlide key={product.pk}>
            <ProductItem
              product={product}
              width={316}
              height={316}
              index={index}
            />
          </SwiperSlide>
        ))}
      </SwiperReact>
      <div className="hidden lg:flex justify-center items-center z-10 absolute right-0 rtl:right-full -translate-y-10">
        <div
          id={`swiper-forward-` + data?.slug}
          className="flex justify-center cursor-pointer bg-transparent pr-1 pl-4 py-4"
        >
          <Icon className="rtl:rotate-180" name="chevron-right" size={18} />
        </div>
      </div>
    </div>
  );
}
