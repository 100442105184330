'use client';

import { Image } from '@akinon/next/components/image';
import { Icon, Link } from 'components';
import clsx from 'clsx';
import { useEffect } from 'react';
import { pushEventGA4 } from '@theme/utils/gtm';

type TransparentItemsProps = {
  transparent_background_items: [
    {
      value: {
        text: string;
        description: string;
        button_link: string;
        button_text: string;
        image_alt: string;
      };

      kwargs: {
        value: {
          image: {
            url: string;
          };
        };
      };
    }
  ];
};

export default async function TransparentItems(props: {
  data: { attributes: TransparentItemsProps; name: string; slug: string };
}) {
  const { data } = props;


  const ecommerce = {
    creative_name: data?.name ?? '',
    creative_slot: data?.slug ?? '',
    promotion_id: data?.slug ? data?.slug?.replaceAll('-', '_') + '_0' : '',
    promotion_name: data?.name ? data?.name + ' 0' : ''
  };

  const customizePushEventData = (item, index) => {
    return {
      ...ecommerce,
      promotion_id: `${props?.data?.slug?.replaceAll('-', '_')}-${index}`,
      promotion_name: item?.value?.text ?? `${props?.data?.name} ${index}`
    };
  };

  const pushSelectEvent = (item, index) => {
    pushEventGA4({
      event: 'select_promotion',
      ecommerce: customizePushEventData(item, index)
    });
  }

  useEffect(() => {
    data?.attributes?.transparent_background_items?.map((item, index) => {
      pushEventGA4({
        event: 'view_promotion',
        ecommerce: customizePushEventData(item, index)
      });
    })
  }, [data]);

  if (!data?.attributes?.transparent_background_items) return null;

  return (
    <div className="bg-gray-20 pb-7 lg:pb-[79px]">
      <div
        className={clsx(
          'container px-4 2xl:px-0 lg:grid gap-x-8 space-y-8 lg:space-y-0',
          `grid-cols-${data?.attributes?.transparent_background_items.length}`
        )}
      >
        {data.attributes.transparent_background_items.map((item, index) => (
          <div
            key={index}
            className="relative flex items-center justify-center"
          >
            <Image
              src={item.kwargs?.value?.image.url}
              alt={item.value?.image_alt}
              fill={true}
              sizes="100vw"
              aspectRatio={432 / 464}
              className="w-full h-[378px] lg:h-full"
            />

            <div
              className={clsx(
                'absolute w-[312px] h-[338px] sm:w-[384px] sm:h-[416px] bg-gray-20/40',
                'flex flex-col items-center justify-center text-center',
                'space-y-4 lg:space-y-8 px-4 sm:px-14 py-[65px] sm:py-[84px]'
              )}
            >
              <h3 className="min-h-20 line-clamp-2 text-[35px] leading-[1.37] font-serif">
                {item.value?.text}
              </h3>
              <p className="h-10 line-clamp-2 text-[11px] leading-[1.82]">
                {item.value?.description}
              </p>
              {item.value?.button_link && item.value?.button_text && (
                <Link
                  href={item.value.button_link}
                  className={clsx(
                    'h-12 bg-black text-white text-sm',
                    'flex items-center px-7',
                    'space-x-4 rtl:space-x-reverse uppercase',
                    'transition-all hover:bg-secondary'
                  )}
                  onClick={() => pushSelectEvent(item, index)}
                >
                  <span className="mt-1">{item.value.button_text}</span>
                  <Icon
                    name="arrow-right"
                    size={12}
                    className="rtl:rotate-180"
                  />
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
