'use client';

import { useEffect, useRef, useState } from 'react';
import { SwiperReact, SwiperSlide, SwiperNavigation, Icon } from '.';
import { Autoplay } from 'swiper';
import { ImageType } from '@akinon/next/types';
import clsx from 'clsx';

import TripeSliderContent from './triple-slider-content';
import DoubleSliderContent from './double-slider-content';
import { pushEventGA4 } from '@theme/utils/gtm';

interface ContentTitle {
  value: {
    title: string;
  };
}

interface ContentItem {
  description: string;
  image: ImageType;
  image_alt: string;
  link: string;
  button_text: string;
  button_link: string;
  second_link: string;
  second_link_text: string;
  disabled: string;
}

interface SliderOptions {
  column: string;
  loop?: boolean;
}

type WidgetSliderProps = {
  data: any;
  content: ContentItem[];
  content_title: ContentTitle;
  sliderOptions: SliderOptions;
};

const WidgetSlider = (props: WidgetSliderProps) => {
  const containerHeight = useRef(null);
  const [arrowHeight, setArrowHeight] = useState(0);

  const ecommerce = {
    creative_name: props?.data?.name ?? '',
    creative_slot: props?.data?.slug ?? '',
    promotion_id: props?.data?.slug ? props?.data?.slug?.replaceAll('-', '_') + '_0' : '',
    promotion_name: props?.data?.name ? props?.data?.name + ' 0' : ''
  };

  const customizePushEventData = (item, index) => {
    return {
      ...ecommerce,
      promotion_id: `${props?.data?.slug?.replaceAll('-', '_')}-${index}`,
      promotion_name: item?.value?.button_text ?? `${props?.data?.name} ${index}`
    };
  };

  useEffect(() => {
    const iamgeHeight = containerHeight?.current?.querySelectorAll(
      '[data-image-container]'
    )[0].offsetHeight;

    setArrowHeight(containerHeight?.current?.offsetHeight - iamgeHeight);

    props.content?.map((item, index) => {
      pushEventGA4({
        event: 'view_promotion',
        ecommerce: customizePushEventData(item, index)
      });
    })
  }, []);

  if (!props) return null;

  const SliderContent = (arg, index) => {
    const pushSelectEvent = (item, index) => {
      pushEventGA4({
        event: 'select_promotion',
        ecommerce: customizePushEventData(item, index)
      });
    }

    switch (props.sliderOptions.column) {
      case 'triple':
        return <TripeSliderContent key={index} content={arg} index={index} pushSelectEvent={pushSelectEvent} />;
      case 'double':
        return <DoubleSliderContent key={index} content={arg} index={index} pushSelectEvent={pushSelectEvent} />;
    }
  };

  const SliderBreakPoints = () => {
    switch (props.sliderOptions.column) {
      case 'triple':
        return {
          breakpoints: {
            240: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          }
        };
      case 'double':
        return {
          breakpoints: {
            240: {
              slidesPerView: 1,
              spaceBetween: 10
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 0
            }
          }
        };
    }
  };

  const defaultSliderOptions = {
    loop: props.sliderOptions.loop,
    autoplay: {
      pauseOnMouseEnter: true
    },
    navigation: {
      nextEl: `.widget-slider-arrow-right-${props.sliderOptions.column}`,
      prevEl: `.widget-slider-arrow-left-${props.sliderOptions.column}`,
      disabledClass: 'opacity-50'
    }
  };

  const SliderOtherOptions = () => {
    switch (props.sliderOptions.column) {
      case 'triple':
        return {
          ...defaultSliderOptions,
          autoplay: {
            ...defaultSliderOptions.autoplay,
            delay: 5000
          }
        };
      case 'double':
        return {
          ...defaultSliderOptions,
          autoplay: {
            ...defaultSliderOptions.autoplay,
            delay: 3000
          }
        };
    }
  };

  return (
    <div className="relative bg-gray-20 px-3 lg:px-0">
      <div ref={containerHeight} className="relative container py-8">
        <div
          className={`${
            props.sliderOptions.column === 'double' ? 'lg:-mx-4' : ''
          }`}
        >
          <h2
            className={`h-8 text-2xl lg:text-4xl mb-5 font-serif ${
              props.sliderOptions.column === 'double' ? 'lg:px-4' : ''
            }`}
          >
            {props.content_title?.value?.title}
          </h2>

          <div
            className={clsx(
              'absolute hidden sm:flex items-center',
              'h-full right-0 xl:right-2 2xl:-right-8 z-10'
            )}
            style={{ height: `calc(100% - ${arrowHeight}px)` }}
          >
            <div
              className={clsx(
                'cursor-pointer',
                `widget-slider-arrow-right-${props.sliderOptions.column}`
              )}
            >
              <Icon name="arrow-right" size={12} />
            </div>
          </div>

          <div
            className={clsx(
              'absolute hidden sm:flex items-center',
              'h-full left-0 xl:left-2 2xl:-left-8 z-10'
            )}
            style={{ height: `calc(100% - ${arrowHeight}px)` }}
          >
            <div
              className={clsx(
                'cursor-pointer',
                `widget-slider-arrow-left-${props.sliderOptions.column}`
              )}
            >
              <Icon name="arrow-left" size={12} />
            </div>
          </div>

          <SwiperReact
            modules={[SwiperNavigation, Autoplay]}
            {...SliderOtherOptions()}
            breakpoints={SliderBreakPoints().breakpoints}
          >
            {props.content?.map((item, index) => (
              <SwiperSlide key={index} className="mobile-center-slide group">
                {SliderContent(item, index)}
              </SwiperSlide>
            ))}
          </SwiperReact>
        </div>
      </div>
    </div>
  );
};

export default WidgetSlider;
