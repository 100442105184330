'use client';

import { Icon, Image } from '.';
import clsx from 'clsx';

const TripeSliderContent = (props) => {
  if (!props.content) return null;

  return (
    <div>
      <div data-image-container className="relative">
        <Image
          src={props.content.kwargs?.value?.image?.url}
          alt={props.content.value?.image_alt}
          fill
          className="w-[260px] sm:w-full"
        />
        {props.content.value.button_link && props.content.value.button_text && (
          <div
            className={clsx(
              'w-full absolute flex items-center justify-center',
              'inset-y-1/2 m-auto'
            )}
          >
            <a
              href={props.content.value.button_link}
              className={clsx(
                'py-2 px-6 lg:px-8',
                'flex items-center justify-center',
                'text-xs lg:text-lg xl:text-2xl bg-primary text-white',
                'space-x-2 rtl:space-x-reverse transition-all hover:bg-secondary'
              )}
              onClick={() => {
                props.pushSelectEvent(props.content, props.index)
              }}
            >
              <span>{props.content.value.button_text}</span>
              <Icon name="arrow-right" size={12} className="rtl:rotate-180" />
            </a>
          </div>
        )}
      </div>

      {props.content.value.description && (
        <p
          dangerouslySetInnerHTML={{
            __html: props.content.value.description
          }}
          className="h-10 2xl:h-12 line-clamp-2 text-black-400 text-center text-sm px-8 my-4"
        />
      )}
    </div>
  );
};

export default TripeSliderContent;
