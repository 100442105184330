'use client';

import { Autoplay } from 'swiper';
import {
  Icon,
  Link,
  SwiperNavigation,
  SwiperReact,
  SwiperSlide
} from 'components';
import clsx from 'clsx';
import { Image } from 'components/image';
import { DynamicSliderType } from 'types';

export default function DynamicSlider({
  slider,
  top_info,
  slug
}: DynamicSliderType) {
  if (!slider) return null;

  return (
    <div
      className="py-8 lg:py-12"
      style={{ backgroundColor: top_info?.value?.bg_color || '' }}
    >
      <div className="relative container mx-auto px-4 2xl:px-0">
        <h2 className="lg:h-6 text-[26px] text-black-400 mb-8">
          {top_info.value.title}
        </h2>
        <div
          className={clsx(
            'absolute hidden lg:flex items-center right-0 xl:right-2 2xl:-right-8 z-10',
            'top-0 bottom-0'
          )}
          style={{ height: 'calc(100% - 105px)' }}
        >
          <div
            className={clsx(
              `w-6 h-6 flex items-center bg-secondary-50 p-1 rounded-full widget-slider-arrow-right-${slug}`,
              'mt-12 cursor-pointer'
            )}
          >
            <Icon name="arrow-right" size={12} />
          </div>
        </div>
        <div
          className={clsx(
            'absolute hidden lg:flex items-center left-0 xl:left-2 2xl:-left-8 z-10',
            'top-0 bottom-0'
          )}
          style={{ height: 'calc(100% - 105px)' }}
        >
          <div
            className={clsx(
              `w-6 h-6 flex items-center bg-secondary-50 p-1 rounded-full widget-slider-arrow-left-${slug}`,
              'mt-12 cursor-pointer'
            )}
          >
            <Icon name="arrow-left" size={12} />
          </div>
        </div>

        <SwiperReact
          modules={[SwiperNavigation, Autoplay]}
          breakpoints={{
            240: {
              slidesPerView: 1,
              spaceBetween: 16
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 32
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32
            }
          }}
          autoplay={{
            delay: 5000
          }}
          navigation={{
            nextEl: `.widget-slider-arrow-right-${slug}`,
            prevEl: `.widget-slider-arrow-left-${slug}`,
            disabledClass: 'opacity-50'
          }}
        >
          {slider.map((item, index) => (
            <SwiperSlide key={index} className="mobile-center-slide">
              <div className="relative h-[400px] lg:h-[395px]">
                <Image
                  src={item.kwargs.value.image.url}
                  alt={item.value.image_alt}
                  className="w-full h-full object-cover"
                />
                {item.value.button_link && (
                  <Link
                    href={item.value.button_link}
                    className={clsx(
                      'h-12 w-full lg:w-auto absolute flex items-center  justify-between px-3 lg:px-[27.2px] bottom-0 right-0',
                      'space-x-3.5 rtl:space-x-reverse text-sm text-white',
                      'bg-black-500/50 transition-all hover:bg-secondary'
                    )}
                  >
                    <span className="font-sans uppercase text-sm pt-1">
                      {item.value.button_text}
                    </span>

                    <Icon
                      name="arrow-right"
                      size={12}
                      className="rtl:rotate-180"
                    />
                  </Link>
                )}
              </div>
              <div className="text-black-400 text-center mt-4 space-y-4">
                {item.value.title && (
                  <h3 className="h-6 line-clamp-1 text-xl">
                    {item.value.title}
                  </h3>
                )}
                {item.value.description && (
                  <p className="h-12 line-clamp-2 leading-[1.5]">
                    {item.value.description}
                  </p>
                )}
              </div>
            </SwiperSlide>
          ))}
        </SwiperReact>
      </div>
    </div>
  );
}
