'use client';

import { Icon, Image } from '.';
import clsx from 'clsx';

const DoubleSliderContent = (props) => {
  if (!props) return null;

  return (
    <div className="relative">
      <div data-image-container className="relative">
        <Image
          src={props.content.kwargs?.value?.image?.url}
          alt={props.content.value?.image_alt}
          className={clsx('w-[260px] sm:w-full lg:px-4')}
          fill
        />
        {props.content.value.button_link && props.content.value.button_text && (
          <div
            className={clsx(
              'w-full absolute flex items-center justify-center',
              'inset-y-1/2 m-auto'
            )}
          >
            <a
              href={props.content.value.button_link}
              className={clsx(
                'flex items-center justify-center h-8 lg:h-16 px-6 lg:px-8',
                'bg-primary text-white text-xs lg:text-4xl whitespace-nowrap',
                'space-x-2 rtl:space-x-reverse transition-all hover:bg-secondary'
              )}
              onClick={() => {
                props.pushSelectEvent(props.content, props.index)
              }}
            >
              <span>{props.content.value.button_text}</span>
            </a>
          </div>
        )}
      </div>

      {props.content.value.second_link && props.content.value.second_link_text && (
        <div className="lg:absolute right-0 rtl:left-0 rtl:right-[unset] lg:bottom-14">
          <a
            href={props.content.value.second_link}
            className={clsx(
              'flex items-center justify-between',
              'uppercase font-sans bg-primary text-white text-sm',
              'py-3.5 px-6 space-x-8 rtl:space-x-reverse transition-all hover:bg-secondary'
            )}
          >
            <span className="mt-1">
              {props.content.value.second_link_text}
            </span>
            <Icon name="arrow-right" size={12} className="rtl:rotate-180" />
          </a>
        </div>
      )}

      {props.content.value.description && (
        <p
          dangerouslySetInnerHTML={{
            __html: props.content.value.description
          }}
          className="h-10 2xl:h-12 line-clamp-2 text-black-400 text-center text-sm px-8 mt-6"
        />
      )}
    </div>
  );
};

export default DoubleSliderContent;
