'use client';

import { Product } from '@akinon/next/types';
import { Link } from 'components';
import { pushProductClicked } from '../../../utils/gtm';


interface Props {
  product: Product;
  index: number;
}

export const ProductName = (props: Props) => {
  const { product, index} = props;

  const absolute_url = product.absolute_url;
  const product_name = product.name;

  return (
    <Link
      href={absolute_url}
      className="text-sm text-primary leading-5 font-serif w-8/10 text-ellipsis overflow-hidden line-clamp-2 whitespace-normal"
      data-testid={`${product_name}-${index}`}
      onClick={() => pushProductClicked(product)}
    >
      {product_name}
    </Link>
  );
};





