'use client';

import { Link } from 'components';
import { Image } from '@akinon/next/components/image';
import { SingleBannerType } from 'types';
import clsx from 'clsx';
import { useEffect } from 'react';
import { pushEventGA4 } from '@theme/utils/gtm';

export default async function HomeSingleBanner(props: {
  data: { attributes: SingleBannerType; name: string; slug: string };
}) {
  const { data } = props;

  const ecommerce = {
    creative_name: data?.name ?? '',
    creative_slot: data?.slug ?? '',
    promotion_id: data?.slug ? data?.slug?.replaceAll('-', '_') + '_0' : '',
    promotion_name: data?.name ? data?.name + ' 0' : ''
  };

  const customizePushEventData = () => {
    return {
      ...ecommerce,
    };
  };

  const pushSelectEvent = () => {
    pushEventGA4({
      event: 'select_promotion',
      ecommerce: customizePushEventData()
    });
  }

  useEffect(() => {
    pushEventGA4({
      event: 'view_promotion',
      ecommerce: customizePushEventData()
    });
  }, [data]);

  return (
    <>
      {data?.attributes?.single_banner?.value && (
        <div className="mb-7 block relative">
          {data?.attributes?.single_banner?.kwargs?.value.full_image_link
            ?.value == 'true' && (
            <Link
              href={data?.attributes?.single_banner?.value?.url}
              className={clsx('absolute z-10 w-full h-full top-0 left-0')}
              onClick={pushSelectEvent}
            >
              {' '}
            </Link>
          )}
          <Image
            className="hidden xs:block"
            src={data?.attributes?.single_banner?.kwargs?.value?.image?.url}
            alt={data?.attributes?.single_banner?.value?.alt}
            fill
            aspectRatio={3.4}
            sizes={'100vw'}
          />
          <Image
            className="block xs:hidden"
            src={
              data?.attributes?.single_banner?.kwargs?.value?.mobile_image?.url
            }
            alt={data?.attributes?.single_banner?.value?.alt}
            fill
            aspectRatio={1}
            sizes={'100vw'}
          />
        </div>
      )}
    </>
  );
}
