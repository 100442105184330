'use client';

import { useState } from 'react';
import { useLocalization } from '@akinon/next/hooks';
import { Button } from 'components';

export default function Description({ content }) {
  const { t } = useLocalization();
  const [show_more, setShowMore] = useState(false);

  if (!content) return null;

  return (
    <div className="container mx-auto px-4 2xl:px-0">
      <div
        dangerouslySetInnerHTML={{
          __html: content.value?.first_paragraph.value
        }}
        className="text-sm"
      />

      {content.value?.content && (
        <Button
          className="h-12 my-4 px-[28.5px]"
          onClick={() => setShowMore(!show_more)}
        >
          <span className="uppercase text-sm font-serif">
            {show_more
              ? t('category.header.read_less')
              : t('category.header.read_more')}
          </span>
        </Button>
      )}

      <div
        dangerouslySetInnerHTML={{ __html: content.value?.content.value }}
        className={`text-sm overflow-hidden ${show_more ? 'h-auto' : 'h-0'}`}
      />
    </div>
  );
}
